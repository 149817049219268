<template>
  <review-card title="Fenômenos Vasculares">
    <v-list-item
      v-for="(item, index) in vascularPhenomena"
      :key="index"
      class="py-2 item-par"
    >
      <v-list-item-content>
        <v-list-item-title class="mb-3">
          {{ item.vascularPhenomenon.name }}
        </v-list-item-title>
        <property-value
          property="Zona de resolução"
          :value="item.diagnoseResolutionZone.resolutionZone.name"
        />
        <property-value
          property="Ponto afetado"
          :value="item.diagnoseResolutionZone.affectedSpotParTrauma"
        />
        <property-value
          property="Comentários aos terapeutas"
          :value="item.therapistComments"
        />
        <property-value
          property="Comentários ao paciente"
          :value="item.patientComments"
        />
      </v-list-item-content>
    </v-list-item>
  </review-card>
</template>

<script>
export default {
  name: "TherapyReviewVascularPhenomena",
  props: {
    vascularPhenomena: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style></style>
